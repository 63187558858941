<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
					:model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:">
            <el-input 
								v-model.trim="searchForm.coursename" 
								@keyup.enter.native="seach"
								clearable />
          </el-form-item>
          <el-form-item label="是否审核:">
            <el-select 
							v-model="searchForm.collegename" 
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectRef"
							clearable>
              <el-option value="是"></el-option>
              <el-option value="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否支出:">
            <el-select 
							v-model="searchForm.majorname" 
							@keyup.enter.native="seach"
							@visible-change="majornameVisible"
							ref="majornameref"
							clearable>
              <el-option value="是"></el-option>
              <el-option value="否"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="教学点:">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          class="btn-2b5a95"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" align="center" width="150">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.teachername
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="总金额" prop="total" align="center" />
      <el-table-column
        label="现金/银行卡号"
        prop="accountname"
        align="center"
        width="280"
      >
      </el-table-column>
      <el-table-column label="审核状态" prop="approveflag" align="center">
        <template slot-scope="scope">
          {{ approveflag[scope.row.approveflag] }}
        </template>
      </el-table-column>
      <el-table-column label="是否支出" prop="isflag" align="center">
        <template slot-scope="scope">
          {{ isflag[scope.row.isflag] }}
        </template>
      </el-table-column>
      <el-table-column label="报销人" prop="outusername" align="center" />
      <el-table-column label="录入人" prop="insertusername" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
			<el-table-column label="操作" align="center" width="100px">
			  <template slot-scope="scope"
			    ><el-button @click="btnShowDetails(scope.row)" type="text"
			      >查看明细</el-button
			    >
			  </template>
			</el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
			:close-on-click-modal='false'
      width="60%"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-33"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="老师:">
          {{ dynamicValidateForm.teachername }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:">
          {{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="学习形式:">
          {{ dynamicValidateForm.studytypeval }}
        </el-form-item>
        <el-form-item label="账户名称:">
          {{ dynamicValidateForm.accountname }}
        </el-form-item>
        <el-form-item label="银行/现金:">
          {{ bankorcash[dynamicValidateForm.bankorcash] }}
        </el-form-item>
        <el-form-item label="总金额:">
          {{ dynamicValidateForm.total }}
        </el-form-item>
        <el-form-item label="审核人:">
          {{ dynamicValidateForm.approveusername }}
        </el-form-item>
        <el-form-item label="审核时间:">
          {{
            dynamicValidateForm.approvetime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="录入人:">
          {{ dynamicValidateForm.insertusername }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="报销人:">
          {{ dynamicValidateForm.outusername }}
        </el-form-item>
        <el-form-item label="报销时间:">
          {{ dynamicValidateForm.outtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="审核标识:">
          {{ approveflag[dynamicValidateForm.approveflag] }}
        </el-form-item>
        <el-form-item label="是否支出:">
          {{ isflag[dynamicValidateForm.isflag] }}
        </el-form-item>
        <el-form-item label="考勤附件:">
          {{ dynamicValidateForm.url1 }}
        </el-form-item>
        <el-form-item label="合同附件:">
          {{ dynamicValidateForm.url2 }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 快速新增 -->
    <el-dialog
      class="btn-2b5a95"
			width="70%"
			v-dialogDrag
			:title="dialogTitle"
			:fullscreen="$store.state.global.isFullscreen"
			:close-on-click-modal='false'
      :visible.sync="showAddallModal"
      v-if="showAddallModal"
      append-to-body
    >
      <el-form
        :model="moreItemForm"
        ref="moreItemForm"
        label-width="35%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="分公司">
          <el-select
            v-model="moreItemForm.companyorgname"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'moreItemForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="教学点">
          <el-select
            v-model="moreItemForm.schoolorgname"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeSchool($event, 'moreItemForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="老师:" prop="teachername">
          <el-input v-model="moreItemForm.teachername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(teacherinfo_dialogresult, 'showTeacherModal', {
                schoolorgid: moreItemForm.schoolorgid,
              })
            "
            :disabled="teachDisable"
            >选择老师</el-button
          >
        </el-form-item>
        <el-form-item label="项目类型" prop="projecttype">
          <el-select v-model="moreItemForm.projecttype" clearable>
            <el-option
              v-for="item in projectTypes"
              :key="item.key"
              :value="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年级">
          <el-select v-model="moreItemForm.grade" clearable>
            <el-option
              v-for="item in grades"
              :key="item.key"
              :value="item.val"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校:" prop="collegename">
          <el-input v-model="moreItemForm.collegename" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(collegemajor_dialogresultall, 'showCollegeModal')
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="专业" prop="majorname">
          <el-input v-model="moreItemForm.majorname" disabled />
        </el-form-item>
        <el-form-item label="层次" prop="levelval">
          <el-input v-model="moreItemForm.levelval" disabled />
        </el-form-item>
        <el-form-item label="学习形式" prop="studytypeval">
          <el-select v-model="moreItemForm.studytypeval" clearable>
            <el-option
              v-for="item in studytypevals"
              :key="item.key"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="考勤附件" prop="url1">
          <el-input v-model="moreItemForm.url1" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="() => uploadFile('url1')"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="合同附件" prop="url2">
          <el-input v-model="moreItemForm.url2" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="() => uploadFile('url2')"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="报销人:"
          prop="outusername"
          class="hoveFrom selectBtn"
        >
          <el-input v-model="moreItemForm.outusername" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(user_dialogresult, 'showUserModal')"
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="报销时间" prop="outtime">
          <el-date-picker
            v-model="moreItemForm.outtime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item
          label="支出明细:"
          prop="itemmoneyinitemname"
          v-if="addEditShow"
        >
          <el-input v-model="moreItemForm.itemmoneyinitemname" disabled />
          <el-button
            type="primary"
            class="select-btn"
            @click="
              onSelectData(moneyoutitem_dialogresult, 'showMoneyOutModal')
            "
            >选择明细</el-button
          >
        </el-form-item>
        <div v-if="addEditShow">
          <div class="title">明细</div>
          <div
            v-for="(item, i) in moreItemForm.moreItems"
            :key="i"
            class="more-form-item-w"
          >
            <el-form-item
              label="课程名称"
              :prop="'moreItems.' + i + '.coursename'"
              :rules="{
                required: true,
                message: '必填字段',
                trigger: 'blur',
              }"
            >
              <el-input v-model.trim="item.coursename" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="课酬"
              :prop="'moreItems.' + i + '.amount'"
              :rules="{
                required: true,
                message: '必填字段',
                trigger: 'blur',
              }"
            >
              <el-input v-model.trim="item.amount" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="摘要"
              :prop="'moreItems.' + i + '.zhaiyao'"
              :rules="{
                required: true,
                message: '必填字段',
                trigger: 'blur',
              }"
            >
              <el-input v-model.trim="item.zhaiyao" clearable></el-input>
            </el-form-item>
            <el-button
              v-if="i !== 0"
              @click="onRemoveMoreForm(i)"
              style="margin: 0 10px; height: 40px"
              >删除</el-button
            >
          </div>
          <el-button @click="addFormItem">添加一行</el-button>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('moreItemForm', addEditFlag)"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择教师 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择教师："
			v-dialogDrag
      :visible.sync="showTeacherModal"
      v-if="showTeacherModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmTeacher">确定</el-button>
      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="姓名:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: moreItemForm.schoolorgid },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="身份证:" prop="idcard">
              <el-input 
								v-model.trim="selectSearchForm.idcard" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: moreItemForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="毕业院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: moreItemForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="majorname">
              <el-input 
								v-model.trim="selectSearchForm.majorname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: moreItemForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: moreItemForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">
						重置
					</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="100" />
        <el-table-column label="姓名" prop="name" align="center" />
        <el-table-column label="性别" prop="sex" align="center" />
        <el-table-column
          label="身份证"
          prop="idcard"
          align="center"
          width="200"
        />
        <el-table-column label="职称" prop="positional" align="center" />
        <el-table-column
          label="学历"
          prop="education"
          align="center"
          width="120"
        />
        <el-table-column
          label="毕业院校"
          prop="collegename"
          align="center"
          width="180"
        />
        <el-table-column
          label="专业"
          prop="majorname"
          align="center"
          width="200"
        />
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
          width="200"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: moreItemForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择院校专业 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择院校专业："
      :visible.sync="showCollegeModal"
      v-if="showCollegeModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmCollegemajor">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="院校:" prop="collegename">
              <el-input 
								v-model.trim="selectSearchForm.collegename" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="专业:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
            <el-form-item label="专业层次:" prop="majorlevelval">
              <el-input 
								v-model.trim="selectSearchForm.majorlevelval" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
            <el-form-item label="类别:" prop="majortypeval">
              <el-input 
								v-model.trim="selectSearchForm.majortypeval" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="showQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="院校" prop="collegename" align="center" />
        <el-table-column label="专业" prop="name" align="center" />
        <el-table-column label="层次" prop="majorlevelval" align="center" />
        <el-table-column label="类别" prop="majortypeval" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择支出明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择支出明细"
      :visible.sync="showMoneyOutModal"
      v-if="showMoneyOutModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmMoneyOut">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="费用类型:" prop="moneyoutitemtypeval">
              <el-input
                v-model.trim="selectSearchForm.moneyoutitemtypeval"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
                clearable
              />
            </el-form-item>
            <el-form-item label="支出费用项目:" prop="name">
              <el-input 
								v-model.trim="selectSearchForm.name"
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="showQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column
          label="费用类型名称"
          prop="moneyoutitemtypeval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="支出费用项目名称"
          prop="name"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 选择报销人 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择报销人："
      :visible.sync="showUserModal"
      v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
            <el-form-item label="所属教学点:" prop="schoolorgname">
              <el-input 
								v-model.trim="selectSearchForm.schoolorgname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="showQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				border
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="150" />
        <el-table-column
          label="员工编号"
          prop="code"
          align="center"
          width="150"
        />
        <el-table-column
          label="登录账号"
          prop="name"
          align="center"
          width="150"
        />
        <el-table-column
          label="真实姓名"
          prop="realname"
          align="center"
          width="150"
        />
        <el-table-column label="激活标志" align="center" width="150">
          <template slot-scope="scope">
            {{ isflag[scope.row.activeflag] }}
          </template>
        </el-table-column>
        <el-table-column
          label="职位状态"
          prop="stateval"
          align="center"
          width="150"
        />
        <el-table-column
          label="岗位"
          prop="positionname"
          align="center"
          width="150"
        />
        <el-table-column
          label="性别"
          prop="sexval"
          align="center"
          width="150"
        />
        <el-table-column
          label="联系电话"
          prop="contactphone"
          align="center"
          width="150"
        />
        <el-table-column
          label="工作电话"
          prop="jobphone"
          align="center"
          width="150"
        />
        <el-table-column label="QQ号码" prop="qq" align="center" width="150" />
        <el-table-column
          label="部门"
          prop="departorgname"
          align="center"
          width="150"
        />
        <el-table-column
          label="分公司"
          prop="companyorgname"
          align="center"
          width="150"
        />
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
          width="150"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.totalcount
        "
      ></el-pagination>
    </el-dialog>

    <!-- 支付课酬 -->
    <el-dialog
      class="btn-2b5a95"
      width="60%"
			v-dialogDrag
      :title="dialogTitle"
			:close-on-click-modal='false'
      :visible.sync="showPayModal"
      v-if="showPayModal"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="老师" prop="teachername">
          <el-input v-model="dynamicValidateForm.teachername" disabled />
        </el-form-item>
        <el-form-item label="总金额" prop="total">
          <el-input v-model="dynamicValidateForm.total" disabled />
        </el-form-item>
        <el-form-item label="银行/现金" prop="bankorcash">
          <el-input
            v-model="bankorcash[dynamicValidateForm.bankorcash]"
            disabled
          />
          <el-button
            type="primary"
            class="select-btn"
            @click="onSelectData(account_dialogresult, 'showAccountModal')"
            >选择账户</el-button
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitPayForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择账户 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择账户："
      :visible.sync="showAccountModal"
      v-if="showAccountModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmAccount">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="开户名称:" prop="accountname">
              <el-input 
								v-model.trim="selectSearchForm.accountname"
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )"
								clearable />
            </el-form-item>
            <el-form-item label="银行名称:" prop="bankname">
              <el-input 
								v-model.trim="selectSearchForm.bankname" 
								@keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="200px"
        />
        <el-table-column
          label="开户名称"
          prop="accountname"
          align="center"
          width="250px"
        />
        <el-table-column
          label="余额"
          prop="curmoney"
          align="center"
          width="200px"
        />
        <el-table-column label="现金/银行" align="center" width="100px">
          <template slot-scope="scope">
            {{ bankorcash[scope.row.bankorcash] }}
          </template>
        </el-table-column>
        <el-table-column
          label="卡号"
          prop="cardno"
          align="center"
          width="200px"
        />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
          width="200px"
        />
      </el-table>

      <el-pagination
        background
        @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 负责人审批 -->
    <el-dialog
      class="btn-2b5a95"
      title="负责人审批："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showApprovalModal"
      v-if="showApprovalModal"
      append-to-body
      width="65%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item
          label="审核是否通过："
          prop="passflag"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-select
            v-model="dynamicValidateForm.passflag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in [
                { value: 1, label: '通过' },
                { value: 0, label: '不通过' },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="审批意见："
          prop="approvedesc"
          :rules="[
            {
              required: true,
              message: '必填字段',
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model.trim="dynamicValidateForm.approvedesc"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="headApproval('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 查看明细 -->
    <el-dialog
      class="btn-2b5a95"
      width="70%"
			v-dialogDrag
			:close-on-click-modal='false'
      title="查看明细："
      :visible.sync="showItemModal"
      v-if="showItemModal"
      append-to-body
    >
      <el-button type="primary" @click="addItem">增加</el-button>
      <el-button type="primary" @click="editItem">修改</el-button>
      <el-button type="primary" @click="delItem">删除</el-button>
      <el-table
        :data="detailSelect.list"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" width="150" />
        <el-table-column
          label="课程"
          prop="coursename"
          align="center"
          width="150"
        />
        <el-table-column
          label="课酬"
          prop="amount"
          align="center"
          width="150"
        />
        <el-table-column
          label="摘要"
          prop="zhaiyao"
          align="center"
          width="150"
					:show-overflow-tooltip="true"
        />
        <el-table-column
          label="支出明细"
          prop="itemname"
          align="center"
          width="150"
        />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data) => handleSelectChange(val, selectSearchForm)
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="detailSelect.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 添加明细 -->
    <el-dialog
      class="btn-2b5a95"
      title="添加明细："
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showaddItemModal"
      v-if="showaddItemModal"
      append-to-body
      width="50%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-50"
        status-icon
        label-width="35%"
        :rules="rules"
      >
        <el-form-item label="老师:">
          {{ dynamicValidateForm.teachername }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="年级:">
          {{ dynamicValidateForm.grade }}
        </el-form-item>
        <el-form-item label="院校:">
          {{ dynamicValidateForm.collegename }}
        </el-form-item>
        <el-form-item label="专业:"
          >{{ dynamicValidateForm.majorname }}
        </el-form-item>
        <el-form-item label="层次:">
          {{ dynamicValidateForm.levelval }}</el-form-item
        >
        <div>
          <div class="title">明细</div>
          <el-form-item label="课程名称" prop="coursename">
            <el-input v-model="dynamicValidateForm.coursename"></el-input>
          </el-form-item>
          <el-form-item label="课酬" prop="amount">
            <el-input v-model="dynamicValidateForm.amount"></el-input>
          </el-form-item>
          <el-form-item label="摘要" prop="zhaiyao">
            <el-input v-model="dynamicValidateForm.zhaiyao"></el-input>
          </el-form-item>
          <el-form-item label="支出明细:" prop="itemname">
            <el-input v-model="dynamicValidateForm.itemname" disabled />
            <el-button
              type="primary"
              @click="
                onSelectData(moneyoutitem_dialogresult, 'showMoneyOutModal')
              "
              >选择明细</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddItemForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  teacherattendance_list,
  teacherattendance_pay,
  teacherattendance_save,
  teacherattendance_delete,
  teacherattendance_saveall,
  teacherattendance_input,
  teacherattendance_approvesave,
  teacherattendance_requestapprove,
  teacherattendancedetail_list,
  teacherattendancedetail_save,
  teacherattendancedetail_input,
  teacherattendancedetail_delete,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _user_dialogresult,
  _account_dialogresult,
  _moneyoutitem_dialogresult,
  _collegemajor_dialogresultall,
  _teacherinfo_dialogresult,
} from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import {
  _projectTypes,
  _approveflag,
  _bankorcash,
} from "@/assets/js/filedValueFlag";
export default {
  mixins: [part],
  name: "teacherattendance",
  components: {},
  props: {
    collegemajor_dialogresultall: {
      default: () => _collegemajor_dialogresultall,
    },
    user_dialogresult: {
      default: () => _user_dialogresult,
    },
    moneyoutitem_dialogresult: {
      default: () => _moneyoutitem_dialogresult,
    },
    teacherinfo_dialogresult: {
      default: () => _teacherinfo_dialogresult,
    },
    account_dialogresult: {
      default: () => _account_dialogresult,
    },
    bankorcash: {
      default: () => _bankorcash,
    },
  },
  data() {
    return {
      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      isflag: {
        1: "是",
        0: "否",
      },
      detailSelect: {}, // 查看明细
      addEditShow: false, // 显示明细
      teachDisable: false, // 选择老师
      showPayModal: false, // 支付课酬
      showAccountModal: false, // 银行/现金
      approveflag: _approveflag, // 审核状态
      showApprovalModal: false, //负责人审批
      addEditFlag: null, // 判断点击确定的是新增还是修改
      fds: new FormData(), //上传文件
      showDetailModal: false, // 查看详情
      showAddallModal: false, // 快速新增
      showItemModal: false, // 查看明细
      showaddItemModal: false, // 查看明细-增加
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      moreItemForm: {
        moreItems: [
          {
            coursename: "",
            amount: "",
            zhaiyao: "",
          },
        ],
      }, //快速新增
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        teachername: [{ trigger: "blur", message: "必填字段", required: true }],
        projecttype: [{ trigger: "blur", message: "必填字段", required: true }],
        grade: [{ trigger: "blur", message: "必填字段", required: true }],
        collegename: [{ trigger: "blur", message: "必填字段", required: true }],
        majorname: [{ trigger: "blur", message: "必填字段", required: true }],
        levelval: [{ trigger: "blur", message: "必填字段", required: true }],
        studytypeval: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        url1: [{ trigger: "blur", message: "必填字段", required: true }],
        url2: [{ trigger: "blur", message: "必填字段", required: true }],
        outusername: [{ trigger: "blur", message: "必填字段", required: true }],
        outtime: [{ trigger: "blur", message: "必填字段", required: true }],
        teachername: [{ trigger: "blur", message: "必填字段", required: true }],
        total: [{ trigger: "blur", message: "必填字段", required: true }],
        bankorcash: [{ trigger: "blur", message: "必填字段", required: true }],
        coursename: [{ trigger: "blur", message: "必填字段", required: true }],
        amount: [{ trigger: "blur", message: "必填字段", required: true }],
        zhaiyao: [{ trigger: "blur", message: "必填字段", required: true }],
        itemname: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      // 院校专业
      selectTable: {},
      showUserModal: false, //报销人
      showTeacherModal: false, // 教师
      showCollegeModal: false, // 院校专业
      showMoneyOutModal: false, // 支出明细
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },

      selectSchoolorgid: null,
      selectTableUrl: null, // 选择的路径
      selectSearchForm: {}, // 选择搜索
      projectTypes: _projectTypes, // 项目类型
      grades: [], // 年级
      studytypevals: [], // 学习形式
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.dic_combox({
      list: "grades",
      typecode: "grade",
      that: this,
    });
    this.dic_combox({
      list: "studytypevals",
      typecode: "studytype",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "org_combox",
      "org_comboxchild",
      "dic_combox",
    ]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },

    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(filename) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.moreItemForm[filename] = res.data.url;
        }
      });
    },
		enterSelect(e) {
			if(e.keyCode == 13) {
				this.getDataList();
			}
		},
		visibleType(e){
			if(!e) {
				this.$refs.selectRef.blur();
			}
		},
		majornameVisible(e) {
			if(!e) {
				this.$refs.majornameref.blur();
			}
		},
		reset() {
			this.searchForm = {
				coursename: null,
				collegename: null,
				majorname: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				name: null,
				idcard: null,
				collegename: null,
				majorname: null,
				accountname: null,
				bankname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, { schoolorgid: moreItemForm.schoolorgid });
		},
		queryForm() {
			this.selectSearchForm = {
				collegename: null,
				name: null,
				majorlevelval: null,
				majortypeval: null,
				moneyoutitemtypeval: null,
				realname: null,
				schoolorgname: null
			}
		},
		showQuery() {
			this.queryForm();
			this.onSelectData(this.selectTableUrl, null, null);
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: teacherattendance_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: teacherattendance_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vteacherattendance;
        }
      });
    },

    // 查看明细路由页 api
    btnShowDetails(row) {
      myRequest({
        url: teacherattendancedetail_list,
        data: {
          teacherattendanceid: row.id || row.teacherattendanceid,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = {
            grade: row.grade,
            levelval: row.levelval,
            teachername: row.teachername,
            projecttype: row.projecttype,
            collegename: row.collegename,
            majorname: row.majorname,
            coursename: null,
            amount: null,
            zhaiyao: null,
            itemname: null,
            teacherattendanceid: row.id,
          };
          this.dialogTitle = "查看明细";
          this.showItemModal = true;
          this.detailSelect = {};
          this.detailSelect = res.data.data;
        }
      });
    },

    // 查看明细-添加 click
    addItem() {
      this.showaddItemModal = true;
    },

    // 查看明细-修改 click
    editItem() {
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.showEditRoute(this.multipleSelection[0].id);
      });
    },

    // 查看明细-修改 api
    showEditRoute(id) {
      myRequest({
        url: teacherattendancedetail_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showaddItemModal = true;
          this.dynamicValidateForm = res.data.data.vteacherattendancedetail;
        }
      });
    },

    // 查看明细-删除 click
    delItem() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delItems(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    // 查看明细-删除 api
    delItems(idArr) {
      myRequest({
        method: "post",
        url: teacherattendancedetail_delete,
        data: this.$qs.stringify(
          {
            ids: idArr,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.btnShowDetails(this.dynamicValidateForm);
        }
      });
    },

    // 查看明细-添加
    submitAddItemForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest({
            url: teacherattendancedetail_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.showaddItemModal = false;
              this.dynamicValidateForm.id =
                this.dynamicValidateForm.teacherattendanceid;
              this.btnShowDetails(this.dynamicValidateForm);
            }
          });
        }
      });
    },

    // 快速新增 click
    btnAddall() {
      // 显示明细
      this.addEditFlag = 0;
      this.addEditShow = true;
      this.teachDisable = false;
      this.dialogTitle = "快速新增";
      this.moreItemForm = {
        url1: null,
        url2: null,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
        moreItems: [
          {
            coursename: "",
            amount: "",
            zhaiyao: "",
          },
        ],
      };
      this.$set(
        this.moreItemForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.moreItemForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.moreItemForm.companyorgid);
      this.showAddallModal = true;
    },

    //删除快速新增项 click
    onRemoveMoreForm(index) {
      this.moreItemForm.moreItems.splice(index, 1);
    },

    //添加一行 click
    addFormItem() {
      this.moreItemForm.moreItems.push({
        coursename: "",
        amount: "",
        zhaiyao: "",
      });
    },

    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: teacherattendance_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //刷新 click
    btnReload() {
      this.getDataList();
      this.searchForm = {};
    },
    //修改路由页 api
    editRoutePage(id) {
      this.addEditFlag = 1;
      this.addEditShow = false;
      myRequest({
        url: teacherattendance_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.moreItemForm = res.data.data.vteacherattendance;
          this.org_comboxchild(this.moreItemForm.companyorgid);
          this.moreItemForm.outtime = formatDare(
            this.moreItemForm.outtime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.teachDisable = true;
          delete this.moreItemForm.inserttime; //inserttime 该字段不用传
          delete this.moreItemForm.deleteflag; //deleteflag 该字段不用传
          this.showAddallModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName, addEditFlag) {
      if (addEditFlag == 0) {
        this.moreItemForm.coursename = [];
        this.moreItemForm.amount = [];
        this.moreItemForm.zhaiyao = [];
        for (let i in this.moreItemForm.moreItems) {
          for (let key in this.moreItemForm.moreItems[i]) {
            if (this.moreItemForm.moreItems[i][key]) {
              //有值才扔，否则是空字符串
              this.moreItemForm[key].push(this.moreItemForm.moreItems[i][key]);
            }
          }
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            myRequest(
              {
                method: "post",
                url: teacherattendance_saveall,
                data: this.$qs.stringify(this.moreItemForm, {
                  arrayFormat: "repeat",
                }),
              },
              {
                isRefTableList: true,
                that: this,

                modal: "showAddallModal",
              }
            );
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            myRequest(
              {
                method: "post",
                url: teacherattendance_save,
                data: this.$qs.stringify(this.moreItemForm, {
                  arrayFormat: "repeat",
                }),
              },
              {
                isRefTableList: true,
                that: this,

                modal: "showAddallModal",
              }
            );
          }
        });
      }
    },

    //切换选项表格页
    handleSelectChange(val, data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl, null, data);
			this.onCloseSelect();
    },

    //选中表格数据改变时 change
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //选项表格 api
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url, modal, data) {
      this.selectPageInfo.pageindex = 1;
      this.onSelectData(url, modal, data);
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //确认教师 click
    onConfirmTeacher() {
      selectCheck(this.multipleSelection, "确认教师", false, () => {
        this.$set(
          this.moreItemForm,
          "teachername",
          this.multipleSelection[0].name
        );
        this.moreItemForm.teacherinfoid = this.multipleSelection[0].id;
        this.showTeacherModal = false;
				this.multipleSelection = [];
				this.selectQuery();
      });
    },

    //确认院校专业 click
    onConfirmCollegemajor() {
      selectCheck(this.multipleSelection, "确认专业", false, () => {
        this.$set(
          this.moreItemForm,
          "majorname",
          this.multipleSelection[0].name
        );
        this.$set(
          this.moreItemForm,
          "collegename",
          this.multipleSelection[0].collegename
        );
        this.$set(
          this.moreItemForm,
          "levelval",
          this.multipleSelection[0].majorlevelval
        );
        this.moreItemForm.collegemajorid = this.multipleSelection[0].id;
        this.moreItemForm.collegeid = this.multipleSelection[0].collegeid;
        this.showCollegeModal = false;
				this.queryForm();
				this.multipleSelection = [];
      });
    },

    //确认支出明细 click
    onConfirmMoneyOut() {
      selectCheck(this.multipleSelection, "确认支出明细", false, () => {
        this.$set(
          this.moreItemForm,
          "warnpercent",
          this.multipleSelection[0].warnpercent
        );
        this.$set(
          this.moreItemForm,
          "itemmoneyinitemname",
          this.multipleSelection[0].name
        );
        this.$set(
          this.dynamicValidateForm,
          "itemname",
          this.multipleSelection[0].name
        );
        this.moreItemForm.itemid = this.multipleSelection[0].id;
        this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
        this.showMoneyOutModal = false;
				this.queryForm();
				this.multipleSelection = [];
      });
    },

    //确认报销人 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认报销人", false, () => {
        this.$set(
          this.moreItemForm,
          "outusername",
          this.multipleSelection[0].realname
        );
        this.moreItemForm.outuserid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.multipleSelection = [];
				this.queryForm();
      });
    },

    // 支付课酬 click
    btnMoneyout() {
      selectCheck(this.multipleSelection, "支付课酬", false, () => {
        this.moneyOutRoute(this.multipleSelection[0].id);
      });
    },

    // 支付课酬路由页 click
    moneyOutRoute(id) {
      this.itemId = id;
      myRequest({
        url: teacherattendance_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dialogTitle = "支付课酬";
          this.dynamicValidateForm = res.data.data.vteacherattendance;
          this.showPayModal = true;
        }
      });
    },

    //确认账户 click
    onConfirmAccount() {
      selectCheck(this.multipleSelection, "确认账户", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "bankorcash",
          this.multipleSelection[0].bankorcash
        );
        this.$set(
          this.dynamicValidateForm,
          "accountname",
          this.multipleSelection[0].accountname
        );
        this.$set(
          this.dynamicValidateForm,
          "cardno",
          this.multipleSelection[0].cardno
        );
        this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
        this.showAccountModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },

    // 支付课酬确定 click
    submitPayForm(dynamicValidateForm) {
      this.$refs[dynamicValidateForm].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: teacherattendance_pay,
              data: {
                id: this.itemId,
                accountid: this.dynamicValidateForm.accountid,
                bankorcash: this.dynamicValidateForm.bankorcash,
              },
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showPayModal",
            }
          );
        }
      });
    },

    //提请审批 click
    btnRequestApprove() {
      selectCheck(this.multipleSelection, "提请审批", true, () => {
        confirmCallBack({
          title: "提示",
          content: "是否提请审批？",
          success: () => {
            this.requestapprove(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },

    //提请审批 api
    requestapprove(ids) {
      myRequest(
        {
          method: "post",
          url: teacherattendance_requestapprove,
          data: this.$qs.stringify(
            {
              ids: ids,
            },
            { arrayFormat: "repeat" }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //检查是否可以审批 api
    btnApprove() {
			this.ids = this.multipleSelection.map((i) => i.id);
     if (this.multipleSelection[0].approveflag !== 1) {
        this.dynamicValidateForm = {};
        this.showApprovalModal = true;
        this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
      } else {
        this.$message({
          type: "warning",
          message: "已审批, 无需再审",
        });
      }
    },

    //负责人审批 api
		headApproval(dynamicValidateForm) {
			this.$refs[dynamicValidateForm].validate((vaild) => {
				if(vaild) {
					myRequest(
						{
							method:"post",
							url: teacherattendance_approvesave,
							data: this.$qs.stringify(
								{
									ids: this.ids,
									passflag: this.dynamicValidateForm.passflag,
									approvedesc: this.dynamicValidateForm.approvedesc,
									leadertype: "03",
								},
								{
								  arrayFormat: "repeat",
								}
							)
						},
						{
						  isRefTableList: true,
						  that: this,
						  modal: "showApprovalModal",
						}
					)
				}

			})
			
		}
  },
};
</script>

<style  lang="scss">
</style>